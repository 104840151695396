import React from "react"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"

class EventTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    const { previous, next } = this.props.pageContext
    // console.log(previous, next)
    return (
      <Layout>
        <SEO title={post.title} />
        <div class="page-headline">
          <div class="container">
            <div class="section-heading text-center">
              <h5 class="font-weight-bold text-uppercase text-orange mb-0">
                Event
              </h5>
              <h1>
                {post.title}
              </h1>
              <p class="text-white-50">
                <i class="fa fa-calendar-o pr-1" />
                {post.publishedDate}
              </p>
            </div>
          </div>
        </div>
        <div className="page-content">
          <Container>
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.description),
                  }}
                />
                <div className="mt-4">
                	<a href={post.registerLink} className="btn btn-primary arrow" target="_blank" rel="noopener noreferrer">Register for Replay Video here</a>
                </div>
              </div>
            </div>
            <div class="blog-nav mt-5">
              <hr/>
              <div class="row mt-4">
                <div class="col-md-6 text-md-left">
                  {previous ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link to={`/events/${previous.slug.current}`}>{previous.title}</Link>
                    </div>
                  ) : null}
                </div>
                <div class="col-md-6 text-md-right">
                  {next ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link to={`/events/${next.slug.current}`}>{next.title}</Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default EventTemplate

export const eventQuery = graphql`
  query eventTemplateQuery($id: String!) {
    post: sanityEvents(id: { eq: $id }) {
	    id
	    title
	    subTitle
	    description
	    publishedDate(locale: "US", formatString: "MMMM DD, YYYY")
	    registerLink
    }
  }
`
